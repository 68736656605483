import React from "react"
import { Link } from "gatsby"

import WAPOHeader from '../assets/WAPO-header.png';
import WAPOMapCard from '../assets/WAPO-map-card.png';
import WAPOcounties from '../assets/WAPO-counties.png';
import WAPOsmMap from '../assets/WAPO-smallmap.png';
import WAPOcards from '../assets/WAPO-cards.png';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img className="col-10 margin-btm-lg wapo-header col-100" src={WAPOHeader} alt="Mariana Tek Site Design" />
            <div className="align-left margin-top-neg-md">
              <h1 className="header--medium margin-btm-xxs">Washington Post - 2016 Election</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>While at Development Seed, we worked with a small team at The Washington Post to create all graphics for the 2016 Presidential, Senate, Congressional and Gubernatorial races. The goal for this project was to add in as much storytelling as possible - to allow viewers to understand what happened / is happening even if they step away.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Design</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Design / UX</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Some HTML / CSS</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-5 row-padding-md flex flex-center col-100 mobile-margin-btm-sm">
              <h3 className="header--small">Allowing for More Storytelling</h3>
              <p>To best communicate the story of the election, I included areas where The Washington Post writers could insert snippets of the latest news. I also added cards that popped up with alerts. These alerts / cards provided the viewer with something more obvious on the map to best understand what was happening.</p>
            </div>
            <div className="column column2 col-7 col-100">
              <div className="project">
                <img className="withShadow" src={WAPOMapCard} alt="WAPO map with pop up card that shows what is currently happening" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth process-bg process-bg-sm">
        <div className="section-internal">
          <div className="align-center margin-btm-md">
            <h3 className="header--small">Process</h3>
          </div>
          <div className="flex-grid-4-across">
            <div>
              <h4 className="header--smallest">Kickoff / Discovery</h4>
              <p>I lead the kickoff meeting with The Washington Post team, where we hashed out goals and what they were hoping to achieve with this project.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Wireframes / Coordination with Devs</h4>
              <p>I built a set of wireframes as a way to communicate and agree upon functionality with internal and external teams.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Design</h4>
              <p>Working closely with The Washington Post team, I designed the features, in mobile, desktop and tablet that we agreed upon in our planning sessions. The design focused on making the content as readable and organized as possible, while telling a story.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Build</h4>
              <p>Once the developers met certain check-in points or finished pieces of functionality, I would tweak the HTML/CSS to make sure it was exactly what we wanted it to be.</p>
              <span className="text-light">Myself</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-6 row-padding-md row-padding-md-mob-none col-100 mobile-margin-btm-xs">
              <div className="project">
                <img className="withShadow project-with-border" src={WAPOcounties} alt="WAPO list of all VA Counties and results" />
              </div>
            </div>
            <div className="column column2 col-6 col-100">
              <div className="margin-btm-md">
                <h3 className="header--small col-10">Exercise in Organizing Data in a Small Space</h3>
                <p>The challenge of this project was fitting the very large amount of data into a small space, while also telling a story. Below are some examples of how I reigned in the data and also pages that provided viewers a way to dive deeper into what was happening.</p>
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={WAPOsmMap} alt="Zoom in on Iowa and its counties" />
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={WAPOcards} alt="Cards with more information on each race" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-MTillustrations">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link className="project-link" to="/MT-illustrations/">
                  <h3 className="header--medium">Mariana Tek - Illustrations</h3>
                </Link>
                <span className="description">Design</span>
              </div>
              <Link className="project-link" to="/MT-illustrations/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
